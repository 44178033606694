@if (topicsForm(); as topicsForm) {
  <section [formGroup]="topicsForm" class="topic-container">
    @if (topicsHighlighted().length > 0) {
      <div class="highlighted">
        <h3 class="highlighted-title">
          {{ 'highlighted_topics.intro' | translate }}:
        </h3>
        @for (
          topicHighlighted of topicsHighlighted();
          track topicHighlighted.id
        ) {
          <div class="highlighted-actions-container">
            <mat-checkbox
              [attr.data-e2e-subscribed]="topicHighlighted.subscribed"
              [attr.data-e2e-topic-title]="topicHighlighted.title"
              [formControlName]="getFormControlName(topicHighlighted)"
              (change)="
                updateSubscriptionStatus(topicHighlighted.id, $event.checked)
              "
            >
              <h4>
                {{ topicHighlighted.title | translate }}
                @if (topicHighlighted.mandatory) {
                  <span class="topic-title">*</span>
                }
              </h4>
              <p>{{ topicHighlighted.description | translate }}</p>
            </mat-checkbox>
          </div>
        }
      </div>
      <h3 class="other-topic">{{ 'other_topics' | translate }}:</h3>
    }
    @for (
      topicNotHighlighted of topicsNotHighlighted();
      track topicNotHighlighted.id
    ) {
      <div class="not-highlighted">
        <mat-checkbox
          [attr.data-e2e-subscribed]="topicNotHighlighted.subscribed"
          [attr.data-e2e-topic-title]="topicNotHighlighted.title"
          [formControlName]="getFormControlName(topicNotHighlighted)"
          (change)="
            updateSubscriptionStatus(topicNotHighlighted.id, $event.checked)
          "
        >
          <h4>
            {{ topicNotHighlighted.title | translate }}
            @if (topicNotHighlighted.mandatory) {
              <span class="topic">*</span>
            }
          </h4>
          <p>{{ topicNotHighlighted.description | translate }}</p>
        </mat-checkbox>
      </div>
    }
  </section>
  <p>* {{ 'mandatory_topics_expl' | translate }}</p>
}
