export enum LanguageCode {
  en = 'en',
  en_UK = 'en_UK',
  fr = 'fr',
  fr_CA = 'fr_CA',
  da = 'da',
  de = 'de',
  sv = 'sv',
  es = 'es',
  el = 'el',
}

export const LanguageCodeToLanguageMap = new Map<LanguageCode, string>([
  [LanguageCode.en, 'English'],
  [LanguageCode.en_UK, 'English, United Kingdom'],
  [LanguageCode.fr, 'Français'],
  [LanguageCode.fr_CA, 'Français, Canada'],
  [LanguageCode.da, 'Dansk'],
  [LanguageCode.de, 'Deutsch'],
  [LanguageCode.sv, 'Svenska'],
  [LanguageCode.es, 'Español'],
  [LanguageCode.el, 'Ελληνικά'],
]);
