export function camelize(str: string): string {
  if (!str) {
    return '';
  }

  return str
    .replace(/(^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}
