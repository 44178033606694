import { Component } from '@angular/core';
import { TranslatePipe } from '../../shared/pipes';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  standalone: true,
  imports: [MatIcon, TranslatePipe],
})
export class ConfirmationComponent {}
