import { Topic } from '../../shared/models';

export class UpdateSubscriptionStatus {
  static readonly type = '[TOPICS] Update subscription status';
  constructor(
    public topicId: string,
    public value: boolean,
  ) {}
}

export class UnsubscribeAll {
  static readonly type = '[TOPICS] Unsubscribe all';
}

export class SetTopics {
  static readonly type = '[TOPICS] Set Topics';
  constructor(public topics: Topic[]) {}
}
