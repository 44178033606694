// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment-type';

export const environment: Environment = {
  name: 'testing',
  production: false,
  clients: {
    backendClient: {
      apiUrl: 'https://message-dispatcher-api-testing.akelius.com',
    },
    dictionaryServiceClient: {
      apiUrl: 'https://dictionary-service.akelius.com/api',
      appId: 'MDIS',
      env: 'test',
    },
  },
  sentry: {
    dsn: 'https://8e33c22b35664aa2bb9755a1dddfd9df@o143872.ingest.sentry.io/6567146',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
