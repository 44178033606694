import { Component, computed, inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Topic } from '../../shared/models';
import { camelize } from '../../shared/utils';
import { TopicsState, UpdateSubscriptionStatus } from '../../core/state';
import { TranslatePipe } from '../../shared/pipes';
import { Store } from '@ngxs/store';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-topics-list',
  templateUrl: './topics-list.component.html',
  styleUrls: ['./topics-list.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatCheckbox, TranslatePipe],
})
export class TopicsListComponent {
  private readonly store = inject(Store);
  topics = this.store.selectSignal(TopicsState.topics);
  topicsForm = computed(() => this.initForm(this.topics()));
  topicsHighlighted = computed(() =>
    this.topics().filter((topic) => topic?.highlighted),
  );
  topicsNotHighlighted = computed(() =>
    this.topics().filter((topic) => !topic?.highlighted),
  );

  initForm(topics: Topic[]): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    topics.forEach((topic) => {
      formGroup.addControl(
        this.getFormControlName(topic),
        new UntypedFormControl({
          value: topic.subscribed,
          disabled: topic.mandatory,
        }),
      );
    });
    return formGroup;
  }

  updateSubscriptionStatus(topicId: string, value: boolean): void {
    this.store.dispatch(new UpdateSubscriptionStatus(topicId, value));
  }

  getFormControlName(topic: Topic): string {
    return camelize(topic.title);
  }
}
