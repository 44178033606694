/**
 * Module must be in CommonJS format
 */

const packageName = '@akelius/icon-library';

/**
 * Source folder within the package
 */
const sourceDir = 'icons';

/**
 * Icon names to copy
 */
const iconList = [
  'ak-warning',
  'ak-mail',
  'ak-mail-open',
  'ak-error',
  'ak-close',
  'ak-success',
  'ak-globe-web',
];

/**
 * Destination folder relative to your project root
 */
const destDir = './src/assets/icons';

module.exports = {
  packageName,
  sourceDir,
  iconList,
  destDir,
};
