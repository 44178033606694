import { Translations } from '../../shared/models';
import { LanguageCode } from '../../shared/enums';

export class SetLoading {
  static readonly type = '[App] Set Loading';
  constructor(public loading: boolean) {}
}
export class SetSubmitted {
  static readonly type = '[App] Set Submitted';
  constructor(public submitted: boolean) {}
}

export class SetTicket {
  static readonly type = '[App] Set Ticket';
  constructor(public ticket: string) {}
}

export class SetTranslations {
  static readonly type = '[App] Set Translations';
  constructor(public translations: Translations) {}
}

export class SetLanguageCode {
  static readonly type = '[App] Set Language Code';
  constructor(public languageCode: LanguageCode) {}
}
