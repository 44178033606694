import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackendClient } from '../../../environments/environment-type';
import { Observable } from 'rxjs';
import {
  SubscriptionStatePayload,
  SubscriptionStateResponse,
  Topic,
} from '../../shared/models';
import { Store } from '@ngxs/store';
import { AppState } from '../state';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly mdiBackendClient: BackendClient;
  private readonly ticket: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store,
  ) {
    this.mdiBackendClient = environment.clients.backendClient;
    this.ticket = this.store.selectSnapshot(AppState.ticket);
  }

  submitSubscriptionState(
    topics: Topic[],
  ): Observable<SubscriptionStateResponse[]> {
    const payload: SubscriptionStatePayload[] = topics.map((topic) => ({
      id: topic.id,
      subscribed: topic.subscribed,
    }));
    return this.httpClient.post<SubscriptionStateResponse[]>(
      `${this.mdiBackendClient.apiUrl}/subscription/by-ticket/${this.ticket}`,
      payload,
    );
  }
}
