import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BackendClient } from '../../../environments/environment-type';
import { Store } from '@ngxs/store';
import { Subscription, Topic, TopicResponse, User } from '../../shared/models';
import { SetTicket, SetTopics, SetUser } from '../state';
import { captureException } from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private readonly mdiBackendClient: BackendClient;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store,
  ) {
    this.mdiBackendClient = environment.clients.backendClient;
  }

  resolveTicket(): Observable<{}> {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const ticket = urlQueryParams.get('ticket');

    if (!ticket) {
      captureException(new Error('JWT ticket missing'));
      return of({});
    }

    this.store.dispatch(new SetTicket(ticket));

    return this.httpClient
      .get<Subscription>(
        `${this.mdiBackendClient.apiUrl}/subscription/by-ticket/${ticket}`,
      )
      .pipe(
        tap((response: Subscription) => {
          const user = this.mapUser(response);
          const topics =
            response.topics?.length > 0
              ? response.topics.map((topic) => this.mapTopic(topic))
              : [];
          this.store.dispatch([new SetUser(user), new SetTopics(topics)]);
        }),
        catchError((err: HttpErrorResponse) => {
          captureException(err);
          return of({});
        }),
      );
  }

  mapUser(data: Subscription): User {
    return {
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      email: data.email || null,
      emails: data.emails || null,
    };
  }

  mapTopic(data: TopicResponse): Topic {
    return {
      id: data.id || null,
      name: data.name || null,
      title: data.name ? 'subscription_topic.' + data.name + '.title' : null,
      description: data.name
        ? 'subscription_topic.' + data.name + '.desc'
        : null,
      mandatory: data.mandatory,
      subscribed: data.subscribed,
      highlighted: data.highlighted,
    };
  }
}
