import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { AppState, TopicsState, UserState } from './core/state';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { GlobalErrorHandler } from './global-error-handler';
import { IconService, TicketService, TranslateService } from './core/services';
import { provideHttpClient } from '@angular/common/http';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { init } from '@sentry/angular';
import { forkJoin } from 'rxjs';
import { routes } from './app-routes';
import { MaterialModule } from './material.module';

const initFactory =
  (
    iconService: IconService,
    translateService: TranslateService,
    ticketService: TicketService,
  ) =>
  () => {
    /* Sync */
    // initialize sentry
    init({
      ...environment.sentry,
      environment: environment.name,
    });
    iconService.registerIcons();
    /* Async */
    return forkJoin([translateService.init(), ticketService.resolveTicket()]);
  };

export const appConfig: () => ApplicationConfig = () => ({
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(
      MaterialModule,
      NgxsModule.forRoot([AppState, UserState, TopicsState], {
        developmentMode: !environment.production,
      }),
      NgxsReduxDevtoolsPluginModule.forRoot(),
    ),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      multi: true,
      deps: [IconService, TranslateService, TicketService],
    },
  ],
});
