<mat-menu #language="matMenu" class="header-menu">
  @for (languageCode of languageCodes; track languageCode) {
    <button
      [class.active]="translateService.currentLanguageCode === languageCode"
      mat-menu-item
      (click)="onLanguageChange(languageCode)"
    >
      <span>{{ languageCodeToLanguageMap.get(languageCode) }}</span>
    </button>
  }
</mat-menu>
<mat-toolbar class="toolbar-container">
  <img class="akelius-logo" src="assets/akelius_logo.svg" alt="Akelius Logo" />
  <button
    mat-icon-button
    class="header-button"
    aria-label="Language"
    [matMenuTriggerFor]="language"
  >
    <mat-icon svgIcon="ak-globe-web" class="large-icon"></mat-icon>
  </button>
</mat-toolbar>
<div class="container">
  @if (selectors.loading()) {
    <div class="loading-container">
      <mat-spinner></mat-spinner>
    </div>
  } @else {
    @if (selectors.user(); as user) {
      <h1>{{ 'heading' | translate }}</h1>
      <h2 class="for-title">
        {{ 'for' | translate }} {{ displayedUserEmail() }}
      </h2>
      @if (!selectors.submitted()) {
        <div>{{ 'intro_text' | translate }}</div>
        <app-topics-list></app-topics-list>
        <div class="actions-container">
          <a [routerLink]="" (click)="unsubscribeFromAll()">{{
            'unsubscribe_all' | translate
          }}</a>
          <button mat-flat-button color="primary" (click)="submit()">
            {{ 'submit' | translate }}
          </button>
        </div>
      } @else {
        <app-confirmation></app-confirmation>
      }
      <ng-template>
        <app-confirmation></app-confirmation>
      </ng-template>
    } @else {
      <p class="no-user-title">{{ 'no_user' | translate }}</p>
    }
  }
</div>
