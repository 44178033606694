import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  SetTopics,
  UnsubscribeAll,
  UpdateSubscriptionStatus,
} from './topics.actions';
import { Topic, TopicsStateModel } from '../../shared/models';

@State<TopicsStateModel>({
  name: 'topics',
  defaults: {
    topics: [],
  },
})
@Injectable()
export class TopicsState {
  @Selector()
  static topics(state: TopicsStateModel): Topic[] {
    return state.topics;
  }

  @Action(SetTopics)
  setTopics(ctx: StateContext<TopicsStateModel>, { topics }: SetTopics) {
    ctx.setState({
      topics,
    });
  }

  @Action(UpdateSubscriptionStatus)
  updateSubscriptionStatus(
    ctx: StateContext<TopicsStateModel>,
    { topicId, value }: UpdateSubscriptionStatus,
  ) {
    ctx.setState(
      patch({
        topics: updateItem<Topic>(
          (item) => item.id === topicId,
          patch({ subscribed: value }),
        ),
      }),
    );
  }

  @Action(UnsubscribeAll)
  unsubscribeAll(ctx: StateContext<TopicsStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      topics: state.topics.map((topic) => ({
        ...topic,
        subscribed: topic.mandatory,
      })),
    });
  }
}
