import { Component, computed, OnInit } from '@angular/core';
import { LanguageCode, LanguageCodeToLanguageMap } from './shared/enums';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { createSelectMap, Store } from '@ngxs/store';
import {
  AppState,
  SetSubmitted,
  TopicsState,
  UnsubscribeAll,
  UpdateSubscriptionStatus,
  UserState,
} from './core/state';
import { SubscriptionService, TranslateService } from './core/services';
import { SubscriptionStateResponse } from './shared/models';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { TopicsListComponent } from './components/topics-list/topics-list.component';
import { MaterialModule } from './material.module';
import { TranslatePipe } from './shared/pipes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    MaterialModule,
    ConfirmationComponent,
    TopicsListComponent,
    TranslatePipe,
    RouterLink,
  ],
})
export class AppComponent implements OnInit {
  languageCodeToLanguageMap = LanguageCodeToLanguageMap;
  languageCodes: LanguageCode[] = Object.values(LanguageCode);

  selectors = createSelectMap({
    loading: AppState.loading,
    submitted: AppState.submitted,
    user: UserState.user,
    userEmailsArray: UserState.userEmailsArray,
    userEmail: UserState.userEmail,
    topics: TopicsState.topics,
  });

  displayedUserEmail = computed(() => {
    const userEmailsArray = this.selectors.userEmailsArray();
    return userEmailsArray.length > 0
      ? userEmailsArray.join(', ')
      : this.selectors.userEmail();
  });

  constructor(
    public translateService: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly subscriptionService: SubscriptionService,
  ) {}

  ngOnInit(): void {
    // read language code from url query param
    this.route.queryParams.subscribe((params) => {
      const urlLanguageCode = params['lang'];
      if (this.languageCodes.includes(urlLanguageCode)) {
        this.translateService.useLanguage(urlLanguageCode);
      }
    });
    this.translateService.translatePageTitle();
  }

  submit(): void {
    const topics = this.store.selectSnapshot(TopicsState.topics);
    this.subscriptionService
      .submitSubscriptionState(topics)
      .subscribe((response: SubscriptionStateResponse[]) => {
        const mappedResponse = response.map((responseItem) => ({
          topicId: responseItem.id,
          subscribed: responseItem.subscribed,
        }));
        // apply backend response to frontend state to stay in sync
        mappedResponse.forEach((mappedResponseItem) => {
          this.store.dispatch(
            new UpdateSubscriptionStatus(
              mappedResponseItem.topicId,
              mappedResponseItem.subscribed,
            ),
          );
        });
        this.store.dispatch(new SetSubmitted(true));
      });
  }

  unsubscribeFromAll(): void {
    this.store.dispatch(new UnsubscribeAll());
  }

  onLanguageChange(languageCode: LanguageCode): void {
    this.translateService.useLanguage(languageCode);
  }
}
