import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  override handleError(error): void {
    /* Log error to Sentry */
    captureException(error);
  }
}
