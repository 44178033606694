import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { User, UserStateModel } from '../../shared/models';
import { SetUser } from './user.actions';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
  },
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel): User {
    return state.user;
  }

  @Selector()
  static userEmailsArray(state: UserStateModel): string[] {
    return state.user?.emails || [];
  }

  @Selector()
  static userEmail(state: UserStateModel): string {
    return state.user?.email || '';
  }

  @Action(SetUser)
  setUser(ctx: StateContext<UserStateModel>, { user }: SetUser) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user,
    });
  }
}
