import { NgModule } from '@angular/core';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';

const MaterialModules = [
  MatSnackBarModule,
  MatIconModule,
  MatCheckboxModule,
  MatButtonModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatMenuModule,
];

@NgModule({
  imports: MaterialModules,
  exports: MaterialModules,
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        duration: 3000,
      },
    },
  ],
})
export class MaterialModule {}
